export default [
  {
    title: 'Dashboard',
    route: 'dashboard-partner',
    icon: 'icon far fa-home',
    resource: 'dashboard',
  },
  {
    header: 'ฝาก - ถอน ออโต้',
    resource: 'member',
  },
  // {
  //   title: 'สมาชิกเล่นล่าสุด',
  //   route: 'userLastPlay',
  //   icon: 'icon far fa-user-friends',
  //   resource: 'userlastplay',
  // },
  {
    title: 'รายการฝากไม่สำเร็จ',
    route: 'waiting-dep',
    icon: 'icon far fa-exclamation',
    resource: 'waitingdep',
  },
  {
    title: 'รายการฝากออโต้',
    route: 'deposit',
    icon: 'icon far fa-comments-alt-dollar',
    resource: 'deposit',
  },
  {
    title: 'รายการฝากโดยแอดมิน',
    route: 'deposit-fromadmin',
    icon: 'icon far fa-comments-alt-dollar',
    resource: 'depositadmin',
  },
  // {
  //   title: 'รายการฝากสลิปเกาหลี',
  //   route: 'deposit-fromadmin-korea',
  //   icon: 'icon far fa-comments-alt-dollar',
  //   resource: 'depositadmin',
  // },
  {
    title: 'รายการแจ้งฝากเกาหลี',
    route: 'pre-deposit-kr',
    icon: 'icon fad fa-comments-alt-dollar',
    resource: 'pre-deposit-kr',
  },
  {
    title: 'STATEMENT เกาหลี',
    route: 'wait-deposit-kr',
    icon: 'icon fad fa-comments-alt-dollar',
    resource: 'wait-deposit-kr',
  },
  {
    title: 'รายการถอน',
    route: 'withdraw',
    icon: 'icon far fa-hand-holding-usd',
    resource: 'withdraw',
  },
  {
    title: 'รายการถอน (เกาหลี)',
    route: 'withdraw-kr',
    icon: 'icon far fa-hand-holding-usd',
    resource: 'withdraw',
  },
  {
    title: 'สมาชิกใหม่ฝากครั้งแรก',
    route: 'memberfirstdep',
    icon: 'icon far fa-user-friends',
    resource: 'no-deposit-members',
  },
  {
    title: 'สมาชิกใหม่ที่ยังไม่ฝาก',
    route: 'NoDepositMembers',
    icon: 'icon far fa-user-friends',
    resource: 'no-deposit-members',
  },
  {
    title: 'รายงานปิดยอดแอดมิน',
    route: 'admin-firstdep-check',
    icon: 'icon far fa-user-friends',
    resource: 'admin-firstdep-check',
  },
  {
    header: 'ระบบเซียน',
    resource: 'member',
  },
  {
    title: 'แนะนำ (เซียน)',
    route: 'invite-friend-old',
    icon: 'icon fad fa-user-friends',
    resource: 'invite-friend-old',
  },
  {
    title: 'พันธมิตร',
    route: 'invite-friend',
    icon: 'icon far fa-user-friends',
    resource: 'invitefriend',
  },
  {
    title: 'ยอดเสีย & คอมมิชชั่น',
    route: 'BonusCashback',
    icon: 'icon far fa-envelope',
    resource: 'cashback',
  },
  {
    title: 'รายงานการรับเพชร',
    route: 'SystemChip',
    icon: 'icon far fa-diamond',
    resource: 'system-chip',
  },
  // {
  //   title: 'แนะนำเพื่อน',
  //   route: 'invite-friends',
  //   icon: 'icon far fa-user-friends',
  //   resource: 'ref',
  // },
  // {
  //   title: 'ประวัติการฝากเงินซ้ำ',
  //   route: 'deposit-resend',
  //   icon: 'icon far fa-envelope',
  //   resource: 'depresend',
  // },
  {
    header: 'ระบบสมาชิก',
    resource: 'member',
  },
  {
    title: 'สมาชิก',
    route: 'member',
    icon: 'icon far fa-user-friends',
    resource: 'member',
  },
  {
    title: 'ประวัติแก้ไขข้อมูลสมาชิก',
    route: 'history-UserEditlog',
    icon: 'icon far fa-envelope',
    resource: 'usereditlog',
  },
  {
    title: 'ประวัติใช้งานสมาชิก',
    route: 'loguser',
    icon: 'icon far fa-envelope',
    resource: 'loguser',
  },
  // {
  //   title: 'รายงาน Win/Lose',
  //   route: 'report',
  //   icon: 'icon far fa-trophy-alt',
  //   resource: 'sbobetwinlose',
  // },
  {
    title: 'ติดตามลูกค้า',
    route: 'calling',
    icon: 'icon far fa-phone',
    resource: 'outstandingadmin',
  },
  {
    header: 'จัดการแอดมิน',
    resource: 'member',
  },
  {
    title: 'พนักงาน',
    route: 'admin-management',
    icon: 'icon far fa-user-friends',
    resource: 'admin',
  },
  {
    title: 'Admin ดีเด่น',
    route: 'OutstandingAdmin',
    icon: 'icon fa fa-users',
    resource: 'outstandingadmin',
  },
  {
    header: 'ระบบธุรกรรม',
    resource: 'dashboarddetail',
  },
  {
    title: 'ข้อมูลธุรกรรม',
    route: 'dashboard-detail',
    icon: 'icon far fa-chart-line',
    resource: 'dashboarddetail',
  },
  // {
  //   title: 'โยกเงิน',
  //   route: 'movecredit',
  //   icon: 'icon far fa-hand-holding-usd',
  //   resource: 'Agadmin',
  // },
  {
    header: 'บัญชี',
    resource: 'reportdep',
  },
  {
    title: 'รายงานฝาก',
    route: 'ReportsDeposits',
    icon: 'icon far fa-file-alt',
    resource: 'reportdep',
  },
  {
    title: 'รายงานถอน',
    route: 'ReportsWithdraws',
    icon: 'icon far fa-file-alt',
    resource: 'reportwith',
  },
  {
    header: 'รายงาน',
    resource: 'reportdep',
  },
  {
    title: 'สรุปรายการฝากถอน',
    route: 'ReportsDepWit',
    icon: 'icon far fa-analytics',
    resource: 'reportdepwit',
  },
  {
    title: 'สรุปรายการฝากถอน KR',
    route: 'ReportsDepWit_kr',
    icon: 'icon far fa-analytics',
    resource: 'reportdepwit',
  },
  {
    header: 'ระบบ LOGS',
    resource: 'logdepwith',
  },
  // {
  //   title: 'ฝากถอน',
  //   route: 'LogsDepositWithdraw',
  //   icon: 'icon far fa-money-check-edit-alt',
  //   resource: 'logdepwith',
  // },
  // {
  //   title: 'รายงานธนาคาร',
  //   route: 'LogsBanks',
  //   icon: 'icon far fa-file-alt',
  //   resource: 'logbank',
  // },
  {
    title: 'รายงานการ Export',
    route: 'LogExports',
    icon: 'icon far fa-file-alt',
    resource: 'logexports',
  },
  {
    title: 'การรับโบนัส',
    route: 'Bonus',
    icon: 'icon far fa-gift-card',
    resource: 'bonus',
  },
  // {
  //   title: 'ฝาก-ถอน',
  //   route: 'deposit-withdraw',
  //   icon: 'icon far fa-money-check-edit-alt',
  //   resource: 'Agadmin',
  // },
  // {
  //   header: 'Invite Friend',
  //   resource: 'Agadmin',
  // },
  // {
  //   title: 'ส่งข้อความ SMS',
  //   route: 'send-sms',
  //   icon: 'icon far fa-sms',
  //   resource: 'Agadmin',
  // },
  // {
  //   header: 'Ganeral Setting',
  //   resource: 'theme',
  // },
  // {
  //   title: 'ตั้งค่าหน้าลูกค้า',
  //   route: 'customer-setting',
  //   icon: 'icon far far fa-desktop-alt',
  //   resource: 'theme',
  // },
  // {
  //   title: 'ตั้งค่าค่ายเกมส์',
  //   route: 'games-setting',
  //   icon: 'icon far far fa-gamepad',
  //   resource: 'Agadmin',
  // },
  {
    header: 'ตั้งค่าระบบ',
    resource: 'promotion',
  },
  {
    title: 'สร้างคูปอง',
    route: 'SystemCoupon',
    icon: 'icon fa fa-ticket',
    resource: 'coupon',
  },
  // {
  //   title: 'โปรโมชั่น',
  //   route: 'promotion',
  //   icon: 'icon far fa-gift-card',
  //   resource: 'promotion',
  // },
  {
    title: 'ประกาศ',
    route: 'Announcements',
    icon: 'icon far fa-bullhorn',
    resource: 'announcements',
  },
  {
    title: 'ความคิดเห็น',
    route: 'reviews',
    icon: 'icon far fa-comment-alt-lines',
    resource: 'reviews',
  },
  // {
  //   title: 'ตั้งค่าระบบx',
  //   route: 'system-setting',
  //   icon: 'icon far fa-cogs',
  //   resource: 'User',
  // },
  {
    title: 'แบนเนอร์',
    route: 'Banners',
    icon: 'icon fa fa-image',
    resource: 'banners',
  },
  {
    title: 'อันดับ',
    route: 'SystemRanking',
    icon: 'icon fa fa-chart-line',
    resource: 'ranking',
  },
  {
    title: 'โบนัสไทม์',
    route: 'GameBonusTime',
    icon: 'icon fa fa-gamepad',
    resource: 'GameBonusTime',
  },
  // {
  //   title: 'เกมสล็อท',
  //   route: 'GameSlots',
  //   icon: 'icon fa fa-gamepad',
  //   resource: 'game-slots',
  // },
  // {
  //   title: 'อัปเดทเกมส์',
  //   route: 'UpdateGame',
  //   icon: 'icon fa fa-gamepad',
  //   resource: 'updategame',
  // },
  {
    header: 'รวมกิจกรรม',
    resource: 'eventssoccer',
  },
  // {
  //   title: 'แมทช์ที่กำลังมาถึง',
  //   route: 'EventsNextMatch',
  //   icon: 'icon far fa-futbol',
  //   resource: 'Agadmin',
  // },

  // {
  //   title: 'ภารกิจประจำเดือน',
  //   route: 'EventsDailyQuests',
  //   icon: 'icon far fa-envelope',
  //   resource: 'daily-quests',
  // },
  // {
  //   title: 'ทายผลฟุตบอล',
  //   route: 'EventsSoccerPredictionIndex',
  //   icon: 'icon fa fa-futbol',
  //   resource: 'eventssoccer',
  // },
  // {
  //   title: 'ทายหวย',
  //   route: 'EventsLottoPredictionIndex',
  //   icon: 'icon fas fa-money-bill',
  //   resource: 'eventslotto',
  // },
  // {
  //   title: 'กิจกรรมแลกของรางวัล',
  //   route: 'EventsReward',
  //   icon: 'icon far fa-gifts',
  //   resource: 'eventsreward',
  // },
  // {
  //   title: 'กิจกรรมแจกโดยแอดมิน',
  //   route: 'event-bonus',
  //   icon: 'icon far fa-gifts',
  //   resource: 'User',
  // },
  // {
  //   title: 'ประวัติการรับกิจกรรม',
  //   route: 'event-history',
  //   icon: 'icon far fa-gifts',
  //   resource: 'User',
  // },
  // {
  //   title: 'กิจกรรมกล่องสุ่มเพชร',
  //   route: 'event-point',
  //   icon: 'icon far fa-gifts',
  //   resource: 'User',
  // },
  // {
  //   title: 'ประวัติแลกของรางวัล',
  //   route: 'history-redeem',
  //   icon: 'icon far fa-gifts',
  //   resource: 'User',
  // },
  // {
  //   header: 'Lucky wheel',
  //   resource: 'User',
  // },
  {
    title: 'ตั้งค่ากงล้อ (ไทย)',
    route: 'wheel-setting',
    icon: 'icon far far fa-dharmachakra',
    resource: 'wheelsetting',
  },
  {
    title: 'ตั้งค่ากงล้อ (เกาหลี)',
    route: 'wheel-setting-kr',
    icon: 'icon far far fa-dharmachakra',
    resource: 'wheelsetting',
  },
  {
    title: 'รายการเล่นกงล้อเสี่ยงโชค',
    route: 'wheel-history',
    icon: 'icon far far fa-dharmachakra',
    resource: 'wheelhistory',
  },
  // {
  //   header: 'Daily Login',
  //   resource: 'User',
  // },
  {
    title: 'ตั้งค่ารางวัล Daily Login',
    route: 'dailylogin-setting',
    icon: 'icon far fas fa-calendar-alt',
    resource: 'dailysetting',
  },
  {
    title: 'ตั้งค่ารางวัล Daily Quests',
    route: 'dailyquests-setting',
    icon: 'icon far fas fa-calendar-alt',
    resource: 'dailyquestssetting',
  },
  {
    title: 'ตั้งค่าจับไพ่',
    route: 'card-setting',
    icon: 'icon far far fa-spade',
    resource: 'cardsetting',
  },
  {
    title: 'รายการเล่นจับไพ่',
    route: 'card-history',
    icon: 'icon far far fa-spade',
    resource: 'cardhistory',
  },
  {
    title: 'คอมเม้นต์',
    route: 'Reviews',
    icon: 'icon far far fa-comment-lines',
    resource: 'reviews',
  },
  {
    header: 'ลอตเตอรี่ไทย',
    resource: 'thailotto',
  },
  {
    title: 'งวดล็อตเตอรี่',
    route: 'thailotto-round',
    icon: 'icon fad fa-tasks',
    resource: 'thailotto',
  },
  {
    title: 'รายงายการขายล็อตเตอรี่',
    route: 'thailotto-sold',
    icon: 'icon fad fa-file-alt',
    resource: 'thailotto',
  },
  {
    title: 'ล็อตเตอรี่ที่ถูกรางวัล',
    route: 'thailotto-reward',
    icon: 'icon fal fa-ballot-check',
    resource: 'thailotto',
  },
  // AG Admin Management
  // {
  //   header: 'SBOBET Setting',
  //   resource: 'SBO',
  // },
  // {
  //   title: 'โต๊ะ SBOBET',
  //   route: 'sbobet-table',
  //   icon: 'icon far fa-trophy-alt',
  //   resource: 'SBO',
  // },
  // {
  //   title: 'ตั้งค่า SBOBET',
  //   route: 'sbobet-setting',
  //   icon: 'icon far fa-trophy-alt',
  //   resource: 'Agadmin',
  // },

  {
    header: 'AG Admin Management',
    resource: 'setting',
  },
  {
    title: 'ธนาคาร',
    route: 'bank',
    icon: 'icon far fa-analytics',
    resource: 'bank',
  },
  {
    title: 'ตั้งค่าบัญชีฝาก',
    route: 'setting-bank',
    icon: 'icon far fa-clone',
    resource: 'settingbank',
  },
  {
    title: 'ช่องทางรู้จัก',
    route: 'channel',
    icon: 'icon far fa-ad',
    resource: 'channel',
  },
  {
    title: 'ประวัติการเข้าใช้งาน',
    route: 'log-admin',
    icon: 'icon far fa-address-book',
    resource: 'logadmin',
  },
  {
    title: 'ประวัติการอนุมัติรายการถอน',
    route: 'log-withdraw',
    icon: 'icon far fa-address-book',
    resource: 'logwithdraw',
  },
  // {
  //   title: 'จัดการบัญชีเอเย่นต์',
  //   route: 'admin-agent-management',
  //   icon: 'icon far fa-user-secret',
  //   resource: 'Agadmin',
  // },
  // {
  //   title: 'โปรโมชั่น',
  //   route: 'promotion',
  //   icon: 'icon far fa-gift-card',
  //   resource: 'Agadmin',
  // },
  // {
  //   title: 'โปรโมชั่น',
  //   route: 'promotions',
  //   icon: 'icon far fa-gift-card',
  //   resource: 'Agadmin',
  // },
  {
    title: 'จัดการเอเย่นต์',
    route: 'setting',
    icon: 'icon far fa-users-cog',
    resource: 'setting',
  },
  {
    title: 'ประวัติหน้าจัดการเอเย่นต์',
    route: 'history',
    icon: 'icon far fa-file-alt',
    resource: 'aghistorylog',
  },
  {
    title: 'ระบบถอนออโต้ SCB',
    route: 'auto-withdraw-scb',
    icon: 'icon far fa-hand-holding-usd',
    resource: 'autowithscb',
  },
  // {
  //   title: 'บัญชีโยกเงิน',
  //   route: 'movebankcheck',
  //   icon: 'icon far fa-hand-holding-usd',
  //   resource: 'Agadmin',
  // },
  // {
  //   title: 'ระบบถอนออโต้ KBANK',
  //   route: 'auto-withdraw-kbank',
  //   icon: 'icon far fa-hand-holding-usd',
  //   resource: 'Agadmin',
  // },
  // SMS Status
  {
    header: 'SMS & STATEMENT',
    resource: 'waitingdep',
  },
  {
    title: 'Thaibulk SMS',
    route: 'thaibulksms',
    icon: 'icon fal fa-sms',
    resource: 'waitingdep',
  },
  {
    title: 'รายการคืนเงิน (ไทย',
    route: 'deposit-admin',
    icon: 'icon far fa-comments-alt-dollar',
    resource: 'redepadmin',
  },
  {
    title: 'รายการคืนเงิน (เกาหลี)',
    route: 'deposit-kr-admin',
    icon: 'icon far fa-comments-alt-dollar',
    resource: 'redepadmin',
  },
  // {
  //   header: 'CRM Bigdata',
  //   resource: 'Crm',
  // },
  // {
  //   title: ' รายงานประวัติลูกค้า',
  //   route: 'HistoryReport',
  //   icon: 'icon far fa-file-alt',
  //   resource: 'Crm',
  // },
  // {
  //   title: 'ยอดเล่นรายวัน',
  //   route: 'DailyPlayTotal',
  //   icon: 'icon far fa-file-alt',
  //   resource: 'Crm',
  // },
  {
    header: 'SMS & STATEMENTS',
    resource: 'smsandstatementUser',
  },
  {
    title: 'SMS ฝาก',
    route: 'sms-deposit',
    icon: 'icon far fa-comment-alt-dollar',
    resource: 'smsandstatement',
  },
  {
    title: 'SMS ถอน',
    route: 'sms-withdraw',
    icon: 'icon far fa-comment-alt-dollar',
    resource: 'smsandstatement',
  },
  {
    title: 'SMS All',
    route: 'sms-all',
    icon: 'icon far fa-comment-alt-dollar',
    resource: 'smsandstatement',
  },
  {
    title: 'SMS OTP',
    route: 'sms-otp',
    icon: 'icon far fa-comment-alt-exclamation',
    resource: 'smsandstatement',
  },

  {
    title: 'True Money Wallet',
    route: 'true-money',
    icon: 'icon far fa-comment-alt-lines',
    resource: 'smsandstatement',
  },
  {
    title: 'KPLUS GEN',
    route: 'kplus-gen',
    icon: 'icon far fa-university',
    resource: 'smsandstatement',
  },
  {
    title: 'KBANK STATEMENT',
    route: 'kbank-statement',
    icon: 'icon far fa-university',
    resource: 'smsandstatement',
  },
  // {
  //   title: 'KBANK LIVE',
  //   route: 'kbank-live',
  //   icon: 'icon far fa-university',
  //   resource: 'User',
  // },
  // {
  //   title: 'SCB Connect',
  //   route: 'scb-connect',
  //   icon: 'icon far fa-university',
  //   resource: 'User',
  // },
  {
    title: 'SCB Statement',
    route: 'statement',
    icon: 'icon far fa-money-check',
    resource: 'smsandstatement',
  },
  {
    title: 'Crypto STATEMENT',
    route: 'crypto-statement',
    icon: 'icon far fa-university',
    resource: 'smsandstatement',
  },
  // กิจกรรม
  // Setting
  // {
  //   header: 'Admin Management',
  //   resource: 'Agadmin',
  // },
  // {
  //   title: 'Agent List',
  //   route: 'agent-list',
  //   icon: 'SettingsIcon',
  //   resource: 'Agadmin',
  // },
]
